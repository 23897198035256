import { LanguagesEnum } from 'src/app/main/shared/enums/app-languages.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, map, of } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MOCK_USERS } from '../shared/constants/mock-users.consts';
import { CompanyUser, ICompanyUser, User } from '../shared/models/user.model';
import { Store } from '@ngrx/store';
import { AuthState, getMeAction, logoutAction, selectToken, selectUser } from 'src/app/core/store/auth';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly userSubject: BehaviorSubject<User<any, any> | undefined> = new BehaviorSubject<User<any, any> | undefined>(undefined);
  private readonly user$ = this.store.select(selectUser);
  private readonly token$ = this.store.select(selectToken);

  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly store: Store<{ auth: AuthState }>
  ) {
    this.user$.subscribe((user) => this.userSubject.next(user));
  }

  getUser$() {
    return this.user$;
  }

  getUserValue() {
    return this.userSubject.getValue();
  }

  getToken$() {
    return this.token$;
  }
  getToken(): Promise<any> {
    return new Promise((resolve) => {
      this.getToken$().subscribe({
        next: (token) => resolve(token),
        error: (error) => resolve(null),
      });
    });
  }

  getProfile$() {
    this.store.dispatch(getMeAction());
  }

  logout$() {
    this.store.dispatch(logoutAction());
  }

  getCustomersLarge(): Observable<any> {
    return this.http.get<any>('assets/demo/customers.json').pipe((res: any) => res);
  }

  getCompanyUsers(companyId: number | string) {
    return this.apiService
      .get<ICompanyUser[]>(`glue/users?companyId=${companyId}`)
      .pipe(map((companyUsers) => companyUsers.map((user) => new CompanyUser(user))));
  }

  // @todo - Sostituire con il companyId dello user
  getUsersForCompany() {
    // return this.authService.statusUser().pipe(switchMap((u) => this.getCompanyUsers('ru1f7')));
    return of<CompanyUser[]>(MOCK_USERS).pipe(map((cusers) => cusers.map((u) => new CompanyUser(u))));
  }

  updateUserLanguage$(language: LanguagesEnum) {
    return this.apiService.patch<any>(`auth/profile`, {
      language,
    });
  }

  updateUserImage$(image: File) {
    const formData = new FormData();
    formData.append('image', image);
    return this.apiService.put<any>(`auth/profile-photo`, formData);
  }

  sendVerificationEmail$(email: string) {
    return this.apiService.get(`auth/verify?email=${email}`);
  }
}
